//
//   original: marketing-ui/content-sections/heading-description.tsx
//
import dayjs from "dayjs";
import { CTACard } from "./CTACard";

export function HeroText() {
  return (
    <section className="bg-white dark:bg-gray-900 mx-0 mb-0">
      {/* surface-bright: ##F8F9FF */}
      <div className="bg-[white] mx-auto max-w-screen-xl px-4 py-8 lg:px-6 lg:py-16 border-r">
        <div className="max-w-screen-lg text-gray-500 dark:text-gray-400 sm:text-lg">

          {/* Product Hunt Badge */}
          <a
            className="mb-8 inline-flex items-center justify-center lg:justify-start"
            href="https://www.producthunt.com/posts/sales-likelihood-calculator?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-sales&#0045;likelihood&#0045;calculator"
            target="_blank">
            <img
              src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=463327&theme=light"
              alt="Sales&#0032;Likelihood&#0032;Calculator — Will&#0032;They&#0032;Buy&#0063;&#0032;–&#0032;Estimate&#0032;The&#0032;Probability&#0032;w&#0047;&#0032;This&#0032;Free&#0032;Game&#0033; | Product Hunt"
              // style="width: 250px; height: 54px;"
              title="Sales Likelihood Calculator"
              width="250"
              height="54"
            />
          </a> 

          <p className="mb-8 text-xs text-gray-500 font-normal italic">
            From the desk of Rowe Morehouse
          </p>

          <div className=" mb-8 lg:mb-12 flex items-center justify-center md:justify-normal">
            <img src="/images/ph-product-of-the-day.svg" alt="Rowe Morehouse wins #1 Product of the Day" className="mb-0" title="#1 Product of the Day at Product Hunt" width="180"/>
            {/* <img className="mt-0 md:mt-0 lg:mt-4 mb-8" src="/images/delorean-400x160.png" width="330" alt="Sales Pipeline Calculator" title="The Deal you want to close." /> */}
          </div>

          <h2 className="mb-8 text-2xl font-extrabold tracking-tight text-gray-500 dark:text-white">
            Regarding{" "}
            “<a href="/sales-likelihood-calculator" className="text-brand-blue hover:underline dark:text-primary-500 dark:hover:underline" target="_blank">
              Sales Likelihood Calculator
            </a>
            ” …
          </h2>

          <p className="indent-2 md:indent-6 mb-6 font-light dark:text-gray-400 lg:text-xl lg:!leading-relaxed">
            Here's how to{" "}
              <a href="/sales-pipeline-review" className="text-gary-500 hover:underline dark:text-primary-500 dark:hover:underline">
                interpret your score
              </a> for after you play the game, and <span className="font-semibold">word-for-word things you can say</span> in your next sales conversation, based on what you've learned:
          </p>

          <p className="indent-2 md:indent-6 mb-6 font-light dark:text-gray-400 lg:text-xl lg:!leading-relaxed">
            ⇨&nbsp; {" "}
            <a href="/sales-pipeline-review" className="text-brand-blue font-bold hover:underline dark:text-primary-500 dark:hover:underline">
              https://objections.dog/score
            </a>
          </p>

          <hr className="my-8 lg:my-10 md:mx-8" />

          <h1 className="mb-4 text-5xl font-extrabold tracking-tight text-gray-700 dark:text-white">
            Handling Objections
          </h1>

          {/* <h2 className="mb-8 text-2xl font-extrabold tracking-tight text-gray-400 dark:text-white">
            As an executive, you & your team can benefit from my <span className="text-gray-500">playbooks, talk-tracks, value messaging</span>, objection handling, & competitive differentiation scripts.
          </h2> */}

          {/* <h3 className="mb-6 text-xl lg:text-2xl font-extrabold tracking-tight text-gray-700 dark:text-white">
            Since 2003 I've been publishing Business Education related to the topic of Sales.
          </h3> */}
          {/* // This text indicates to google the page "topic" in their taxonomy, also known a "publisher vertical" */}
          
          <h3 className="mb-6 text-xl lg:text-xl font-extrabold tracking-tight text-balance text-gray-700 dark:text-white">
            <span className="italic text-gray-600">Handling Objections</span> is the name of this site, but it's also an activity or component of any sales process or methodology.
          </h3>

          <p className="indent-2 md:indent-6 mb-6 font-light dark:text-gray-400 lg:text-xl lg:!leading-relaxed">
            Objection handling (aka <span className="italic">overcoming objections</span>) is when a salesperson speaks to a prospect's concerns about a product in order to educate, persuade, and advance the sale toward the close. It's a natural part of any sales conversation.
          </p>

          <p className="indent-2 md:indent-6 mb-6 font-light dark:text-gray-400 lg:text-xl lg:!leading-relaxed">
            HEY LOOK I'M A TECH GUY, BUT A BIG part of my job as an agency owner is something called "Sales Enablement" – where I generate a ton of sales training and tactical sales materials (mostly documents) meant <span className="font-bold">to help people improve at sales and selling</span>. I do this for my own reps who I manage, and I also do this for my clients' sales teams.
          </p>

          <p className="indent-2 md:indent-6 mb-6 font-light dark:text-gray-400 lg:text-xl lg:!leading-relaxed">
            It's a lot of writing. Pitch templates, sales playbooks, talk-tracks, discovery questions, sales rebuttals, <a href="/sales-tips/cold-call-script-examples" className="text-brand-blue-600 hover:underline dark:text-primary-500 dark:hover:underline">cold call script</a><span className="text-brand-blue-600 hover:underline dark:text-primary-500 dark:hover:underline">s</span>, battlecards, pipeline management SOPs, etc. That means I spend like weeks at time, doing this all day long.
          </p>
          
          <p className="indent-2 md:indent-6 mb-6 font-light dark:text-gray-400 lg:text-xl lg:!leading-relaxed">
            Now that may sound boring to you, but it's utterly fascinating to me!
          </p>

          <p className="indent-2 md:indent-6 mb-6 font-light dark:text-gray-400 lg:text-xl lg:!leading-relaxed">
            Just take for example any software-as-a-service product. You probably have a few that you pay a monthly subscription fee for.
          </p>

          <p className="indent-2 md:indent-6 mb-6 font-light dark:text-gray-400 lg:text-xl lg:!leading-relaxed">
            What I do is translate <span className="font-bold">software product features</span> into <span className="font-bold">benefit statements</span> that are hyper-targeted to resonate with specific personas in specific vertical markets. Like a vice president, at a specific type of company – for example – who has a business problem to fix, or a compelling event that is prompting him or her to look for a solution.
          </p> 

          <p className="indent-2 md:indent-6 mb-6 font-light dark:text-gray-400 lg:text-xl lg:!leading-relaxed">
            THEN I TRAIN <span className="px-1 mark-purple rounded text-gray-500">salespeople, business owners, founders, & executives</span> on exactly how and when to use the best benefit statements during sales conversations. That, along with a lot of other functional go-to-market strategies & RevOps approaches.
          </p>

          {/* <a href="/persuasive-language-patterns" className="text-brand-blue-600 hover:underline dark:text-primary-500 dark:hover:underline">persuasive language patterns</a> */}

          <p className="indent-2 md:indent-6 mb-6 font-light dark:text-gray-400 lg:text-xl lg:!leading-relaxed">
            The core of what I deliver is "value messaging" and "ROI impact phrases," then the <a href="/sales-tips/handling-of-objections-in-sales" className="text-brand-blue-600 hover:underline dark:text-primary-500 hover:underline">handling of objections</a> to block & tackle around that. That's for reps, but it starts at the top – if you can't explain it, how can you expect them to. 
          </p>

          <p className="indent-2 md:indent-6 mb-6 font-light dark:text-gray-400 lg:text-xl lg:!leading-relaxed">
            Your messaging as perceived by your investors is even more important.
          </p> 

          <p className="indent-2 md:indent-6 mb-6 font-light dark:text-gray-400 lg:text-xl lg:!leading-relaxed">
            LOOK, YOU DON'T JUST GIVE DEMOS. You're an exec, founder or salesperson, not a "demo giver." You have to <span className="font-bold">crisply define the value proposition</span> for each one of your core product features – using ROI impact metrics that your prospects understand and use internally. You'd think this would be easy, <span className="underline">but so many companies get it wrong</span>!
          </p>

          <hr className="my-8 lg:my-10 md:mx-8" />

          {/* TODO: fix the centering of this ornament divider */}
          {/* supposed to work like this: https://flowbite.com/docs/typography/hr/#hr-with-text */}
          {/* <div className="inline-flex items-center justify-center w-full">
              <hr className="w-64 h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
              <span className="absolute px-3 font-medium text-gray-500 -translate-x-1/2 bg-white left-1/2 dark:text-white dark:bg-gray-900 lg:text-2xl">❦</span>
          </div> */}

          <p className="indent-2 md:indent-6 mb-6 font-light dark:text-gray-400 lg:text-xl lg:!leading-relaxed">
            <span className="font-bold">For example:</span> Marketing and sales departments are often saying totally different things – and sometimes none if it even makes sense – like some baloney like: <span className="italic text-gray-400">"Game changer AI enabled takes it to the next level, hands down!"</span>
          </p>

          <p className="indent-2 md:indent-6 mb-6 font-light dark:text-gray-400 lg:text-xl lg:!leading-relaxed">
            Nah. You have real prospects who need real value messaging. Ask yourself: Can your CEO can clearly state a crisp value proposition for your product, and if so, is marketing AND sales both saying it the same way? … or do you have a mish-mash of language half written by past "copywriters" or social media managers who have <em>never worked in sales</em> and don't know <span className="font-bold">what your prospective customer needs to hear in order to buy</span>.
          </p>

          <p className="indent-2 md:indent-6 mb-6 font-light dark:text-gray-400 lg:text-xl lg:!leading-relaxed">
            That's not necessarily your job (or maybe it is?) – <span className="font-bold">but at least YOU should be saying the right things so that <span className="underline">YOU</span> are effective in</span> your sales efforts and you increase your own $$$ personal income.</p>

          <p className="mb-6 font-light dark:text-gray-400 lg:text-xl lg:!leading-relaxed">
            <span className="font-bold">🔥 I want to show you all this stuff!</span> … I've come up with a lot of unique scripts, templates, and approaches – the brass-tacks sh*t that you need inside your fancy AI salestech stack in order for it to really crank. I've done so much technical integration with new apps like <span className="text-gray-700">Clay, Seamless, Common Room, Sendspark, Instantly</span>, and of course <span className="text-gray-700">HubSpot, SFDC</span>, et. al.  
          </p>

          <p className="mb-6 font-light dark:text-gray-400 lg:text-xl lg:!leading-relaxed">
            It's just like GenAI: garbage in = garbage out. You have to have <span className="italic">golden versions</span> of your messaging in order to benefit from your tech-stack, just like how your prompts and custom instructions impact your AI results. Setting up the tech is the easy part. 
          </p>


          <p className="indent-2 md:indent-6 mb-6 font-light dark:text-gray-400 lg:text-xl lg:!leading-relaxed">
            In the meantime, you can have a look at my "<a href="/meddpicc-sales-calculator" className="text-brand-blue-600 hover:underline dark:text-primary-500 dark:hover:underline underline">Sales Likelihood Calculator</a>" – a little game I made based on MEDDPICC that helps sales reps, biz owners, & founders to think about their deals and the opportunities in their pipeline. <span className="font-bold text-brand-red-400">#1 on Product Hunt</span>.
          </p>

          <p className="indent-2 md:indent-6 mb-6 font-light dark:text-gray-400 lg:text-xl lg:!leading-relaxed">
          Have fun, and watch this space! … releasing more stuff soon.
          </p>

          <div className="mb-4 flex items-center divide-x-2 divide-gray-300">
            <a href="https://www.linkedin.com/comm/mynetwork/discovery-see-all?usecase=PEOPLE_FOLLOWS&followMember=rowe-morehouse" target="_blank"><img className="w-16 h-16 rounded-full !my-0 mr-5" src="/og/rowe-morehouse-2024-256x256.jpg" alt="Rowe Morehouse" title="Rowe Morehouse on LinkedIn" /></a>
            <p className="pl-5 !text-brand-green text-xs font-bold italic ">
              {dayjs().subtract(8, "day").format("MMMM D, YYYY")}
              <br />
              <span className="!text-gray-500 text-sm font-normal italic">Rowe Morehouse &middot; </span> <a href="/contact" className="text-sm font-normal text-brand-blue hover:underline dark:text-primary-500 dark:hover:underline">Contact me</a>
            </p>
          </div>

          {/* <p className="mb-4 font-light dark:text-gray-400 lg:text-xl lg:!leading-relaxed"> */}
          {/* PS: I like cats and minimal techno music. Why are you looking at the code?</p> */}
          
          <a href="#handling-objections" className="inline-flex items-center font-bold text-brand-blue-600 dark:text-primary-500 mt-6 lg:mt-8 border-t pt-4 border-gray-300">
            ⇧&nbsp; </a>
          <a href="#handling-objections" className="inline-flex items-center font-bold text-brand-blue-600 dark:text-primary-500 mt-6 lg:mt-8 border-t pt-4 border-gray-300">
            Objections.Dog
          </a>

          {/* links on this page */}
          <p className="pb-2 pt-10 text-gray-500 text-xs font-normal italic">Links on this page:</p>¹{" "}
            <a href="/sales-tips/cold-call-script-examples" id="cold-call-script-examples#links-on-this-page"
            className="text-xs md:text-base text-brand-blue-600 dark:text-primary-500">
              https://objections.dog/sales-tips/cold-call-script-examples
            </a>
            <br />²{" "}
            <a href="/sales-tips/handling-of-objections-in-sales" id="handling-of-objections-in-sales#links-on-this-page"
            className="text-xs md:text-base text-brand-blue-600 dark:text-primary-500">
              https://objections.dog/sales-tips/handling-of-objections-in-sales
            </a>
            <br />³{" "}
            <a id="meddpicc-sales-calculator#links-on-this-page" href="/meddpicc-sales-calculator"
            className="text-xs md:text-base text-brand-blue-600 dark:text-primary-500">
              https://objections.dog/meddpicc-sales-calculator
            </a>
          <br />
          {/* Product Hunt Badge */}
          <a
            className="mt-8 inline-flex items-center justify-center lg:justify-start"
            href="https://www.producthunt.com/posts/sales-likelihood-calculator?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-sales&#0045;likelihood&#0045;calculator"
            target="_blank">
            <img
              src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=463327&theme=light"
              alt="Sales&#0032;Likelihood&#0032;Calculator — Will&#0032;They&#0032;Buy&#0063;&#0032;–&#0032;Estimate&#0032;The&#0032;Probability&#0032;w&#0047;&#0032;This&#0032;Free&#0032;Game&#0033; | Product Hunt"
              // style="width: 250px; height: 54px;"
              title="Sales Likelihood Calculator"
              width="250"
              height="54"
            />
          </a>    

          {/* begin cta card */}
          <div className="col-span-full md:col-span-10 mt-10">
            {/* <CTACard /> */}
          </div>                 
        </div>
      </div>
    </section>
  );
}
